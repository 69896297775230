import React from "react";

function HyperIcon() {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5567 7.54212L13.5326 6.01055C14.406 5.33355 15.5126 5.03125 16.6089 5.17014C17.7052 5.30903 18.7015 5.87773 19.3784 6.75115C20.0554 7.62456 20.3578 8.73114 20.2189 9.82744C20.08 10.9237 19.5113 11.92 18.6379 12.597L16.6619 14.1285M12.7101 17.1917L10.7341 18.7233C9.86073 19.4003 8.75415 19.7026 7.65784 19.5637C6.56154 19.4248 5.56531 18.8561 4.88831 17.9827C4.21131 17.1093 3.90901 16.0027 4.0479 14.9064C4.18679 13.8101 4.75549 12.8138 5.62891 12.1368L7.60483 10.6053M9.49881 14.409L14.768 10.3248"
          stroke="#1264E2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default HyperIcon;
