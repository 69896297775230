import {
  Card,
  Grid,
  FormLabel,
  CircularProgress,
  IconButton,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import SuiPasswordInput from "components/SuiPasswordInput";
import {
  useCreateTenantMutation,
  useGetAllTimezoneMutation,
  useGetAllCountryMutation,
  useGetCityByStateMutation,
  useGetStatesByCountryMutation,
} from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { createTenantSchema } from "Validation";
import {
  useTenantGetByIdMutation,
  useUpdateTenantMutation,
  useGetPlanInfoMutation,
} from "app/features/api/AuthApi";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import WrapCard from "Container/Cards/WrapCard";
import { useGetAllPlanInfoMutation } from "app/features/api/AuthApi";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import WrapCardCalendly from "Container/Cards/WrapCard/WrapCardCalendly";
import { Delete } from "@mui/icons-material";
import { useTestCalendlyTokenMutation } from "app/features/api/AuthApi";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
// import SearchableTypeDropdown from "components/SuiDropDownList/SearchableTypeDropdown";
import AutocompleteSingleSelect from "components/SuiDropDownList/AutocompleteSingleSelect";
const { inputColors, white } = colors;
const useStyles = makeStyles(() => ({
  inputBase: {
    borderRadius: "2px",
    paddingLeft: "0px",
    border: `1px solid #D7DADD`,
    backgroundColor: `${inputColors.borderColor.main}`,
    overflow: "hidden",
    "&:focus-within": {
      border: `1px solid #D7DADD`,
      backgroundColor: `${white.main}`,
      "& input": {
        backgroundColor: `${white.main} !important`,
        "&:-webkit-autofill": {
          // WebkitBoxShadow: "inset 0rem 0rem 0rem 1.875rem #fff !important",
        },
      },
    },
  },
}));
const CreateTenant = (inputProps) => {
  const classes = useStyles();
  const [mobileInValid, setMobileInValid] = useState(false);
  const [error, setError] = useState("");
  const [timezone, setTimezone] = useState([]);
  const [country, setCountry] = useState([]);
  const [statesByCountry, setStatesByCountry] = useState([]);
  const [cityByState, setCityByState] = useState([]);
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [createTenant, { isLoading: createLoader }] = useCreateTenantMutation();
  const [getAllTimezone] = useGetAllTimezoneMutation();
  const [getAllCountry] = useGetAllCountryMutation();
  const [getCityByState] = useGetCityByStateMutation();
  const [getStatesByCountry] = useGetStatesByCountryMutation();
  const { id } = useParams();
  const [tenantGetById, { isLoading: loader }] = useTenantGetByIdMutation();
  const [tenantDataById, setTenantDataById] = useState([]);
  const [updateTenant, { isLoading: updateLoader }] = useUpdateTenantMutation();
  const [plans, setPlans] = useState([]);
  const [calendlyPairs, setCalendlyPairs] = useState([{ name: "", token: "" }]);
  const [getAllPlanInfo] = useGetAllPlanInfoMutation();
  const [testCalendlyToken, { isLoading: calendlyLoader }] =
    useTestCalendlyTokenMutation();
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [planType, setPlanType] = useState([]);
  const [testError, setTestError] = useState([]);
  const { permission } = useSelector((state) => state.auth);
  const loginType = localStorage.getItem("type");
  useEffect(() => {
    if (permission && !permission?.includes("tenant:create")) {
      console.log(permission);
      navigate("/profile");
    }
    if (loginType === "tenant") {
      navigate("/profile");
    }
  }, [permission]);
  console.log("tenantDataById", tenantDataById);

  const initialValues = {
    t_name: id ? tenantDataById?.name : "",
    t_email: id ? tenantDataById?.email : "",
    t_password: "",
    t_confirm_password: "",
    t_company_name: id ? tenantDataById?.companyName : "",
    t_industry_name: id ? tenantDataById?.industry : "",
    t_contact_number: id ? tenantDataById?.contactNumber : "",
    t_company_address: id ? tenantDataById?.companyAddress : "",
    t_timezone: id
      ? tenantDataById?.cb_Timezone?.id !== null &&
        !isNaN(tenantDataById?.cb_Timezone?.id)
        ? parseInt(tenantDataById?.cb_Timezone?.id)
        : ""
      : "",
    t_country: id
      ? tenantDataById?.cb_Country?.id !== null &&
        !isNaN(tenantDataById?.cb_Country?.id)
        ? parseInt(tenantDataById?.cb_Country?.id)
        : ""
      : "",
    t_state: id
      ? tenantDataById?.cb_State?.id !== null &&
        !isNaN(tenantDataById?.cb_State?.id)
        ? parseInt(tenantDataById?.cb_State?.id)
        : ""
      : "",
    t_city: id
      ? tenantDataById?.cb_City?.id !== null &&
        !isNaN(tenantDataById?.cb_City?.id)
        ? parseInt(tenantDataById?.cb_City?.id)
        : ""
      : "",
    t_subscription_id: id
      ? parseInt(tenantDataById?.cb_SubscriptionPlan?.id)
      : "",
    calendlyPairs: id ? tenantDataById?.cb_CalendlyIntegrations : calendlyPairs,
    t_city_name: "",
    t_api_key: tenantDataById?.apiKey,
    t_recipients_email: tenantDataById?.recipientsEmail,
  };
  const isCalendly = planType?.includes("calendly");
  const formik = useFormik({
    initialValues,
    validationSchema: createTenantSchema(id),
    enableReinitialize: id ? true : false,
    onSubmit: async (values) => {
      console.log(values);
      const body = {
        name: values.t_name,
        email: values.t_email,
        companyName: values.t_company_name,
        industry: values.t_industry_name,
        companyAddress: values.t_company_address,
        subscriptionPlanId: values.t_subscription_id,
        contactNumber: values.t_contact_number,
        password: values.t_password,
        confirmPassword: values.t_confirm_password,
        timezoneId: values.t_timezone,
        countryId: values.t_country,
        stateId: values.t_state,
        cityId: values.t_city,
        recipientsEmail: emails,
        apiKey: values.t_api_key,
        calendlyIntegrations: isCalendly ? calendlyPairs : [],
      };

      // DELETE IF FIELD BLANK
      if (!values.t_password) {
        delete body.password;
        delete body.confirmPassword;
      }
      if (!values.t_timezone) {
        delete body.timezoneId;
      }
      if (!values.t_country) {
        delete body.countryId;
      }
      if (!values.t_state) {
        delete body.stateId;
      }
      if (!values.t_city) {
        delete body.cityId;
      }

      try {
        const response = id
          ? await updateTenant({ data: body, id: id }).unwrap()
          : await createTenant(body).unwrap();
        if (response.statusCode === 200) {
          formik.resetForm();
          suiSnackbar(response.message, "success");
          navigate("/tenant");
          setError("");
        }
      } catch (error) {
        suiSnackbar(error?.data?.message, "error");
        if (error?.data.fields.length > 0) {
          return error?.data.fields.filter((val) => {
            suiSnackbar(val, "error");
          });
        } else {
          console.log(error.data);
          suiSnackbar(error?.data.message, "error");
        }
      }
    },
  });
  useEffect(() => {
    console.log(formik.errors, 'formik.error')
  }, [formik.errors]);

  const getTimezone = async () => {
    try {
      const response = await getAllTimezone();
      const res = response?.data?.data?.map((item, index) => {
        return { ...item, label: item?.name };
      });
      setTimezone(res);
    } catch (err) {
      console.log(err);
    }
  };
  const getPlans = async () => {
    try {
      const response = await getAllPlanInfo();
      const res = response?.data?.data?.map((item, index) => {
        return { id: item?.id, label: item?.subscriptionName, type: item.type };
      });
      setPlans(res);
    } catch (err) {
      console.log(err);
    }
  };
  const getCountry = async () => {
    try {
      const response = await getAllCountry();
      const res = response?.data?.data.map((item, index) => {
        return { ...item, label: item?.name };
      });
      setCountry(res);
    } catch (err) {
      console.log(err);
    }
  };
  const getState = useCallback(
    async (id) => {
      try {
        if (id) {
          const response = await getStatesByCountry(id);
          const res = response?.data?.data.map((item, index) => {
            return { ...item, label: item?.name };
          });
          setStatesByCountry(res);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [formik.values.t_country]
  );
  const getCity = async (id) => {
    try {
      if (id) {
        const response = await getCityByState(id);
        const res = response?.data?.data.map((item, index) => {
          return { ...item, label: item?.name };
        });
        setCityByState(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTenantById = async () => {
    try {
      const response = await tenantGetById(id).unwrap();
      setTenantDataById(response?.data);
      setCalendlyPairs(response?.data?.cb_CalendlyIntegrations);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) fetchTenantById();
  }, []);

  useEffect(() => {
    const cityName = cityByState?.filter(
      (item) => item.id === tenantDataById?.cb_City?.id
    );
    formik.setFieldValue("t_city_name", cityName[0]?.name);
  }, [tenantDataById?.cb_City?.id, cityByState]);
  useEffect(() => {
    getTimezone();
    getPlans();
    getCountry();
  }, []);

  useEffect(() => {
    getState(formik.values.t_country);
    getCity(formik.values.t_state);
  }, [formik.values.t_country, formik.values.t_state]);

  const handlePairChange = (index, field, value) => {
    const newPairs = [...calendlyPairs];
    newPairs[index] = { ...newPairs[index], [field]: value };
    setCalendlyPairs(newPairs);
    formik.setFieldValue("calendlyPairs", newPairs);
  };

  const handleRemovePair = (index) => {
    if (calendlyPairs.length > 1) {
      const newPairs = calendlyPairs.filter((_, i) => i !== index);
      setCalendlyPairs(newPairs);
      formik.setFieldValue("calendlyPairs", newPairs);
    }
  };

  const handleAddPair = () => {
    const newPairs = [...calendlyPairs, { name: "", token: "" }];
    setCalendlyPairs(newPairs);
    formik.setFieldValue("calendlyPairs", newPairs); // Ensure Formik is aware of changes
  };

  const handleTestToken = async (token, index) => {
    setLoadingIndex(index);
    const newPairs = [...error];
    try {
      const response = await testCalendlyToken({ token }).unwrap();
      if (response.statusCode === 200) {
        const errorMessageObj = {
          message: response.message,
          index: index,
          type: "success",
        };
        newPairs[index] = errorMessageObj;
        setError((prev) => {
          return newPairs;
        });
      }
    } catch (err) {
      console.log(err);
      const errorMessageObj = {
        message: err.data.message,
        index: index,
        type: "failed",
      };
      newPairs[index] = errorMessageObj;
      setError(newPairs);
    } finally {
      setLoadingIndex(null);
    }
  };

  useEffect(() => {
    if (formik.values.t_subscription_id && plans.length > 0) {
      const filteredSubscription = plans?.filter(
        (item) => item.id === formik.values.t_subscription_id
      );
      const plan = filteredSubscription[0]?.type?.split(", ");
      setPlanType(plan);
      console.log(formik.values.calendlyPairs.length);
      if (id && plan && formik.values.calendlyPairs.length === 0) {
        setCalendlyPairs([{ name: "", token: "" }]);
      }
    }
  }, [formik.values.t_subscription_id, plans]);

  const [emails, setEmails] = useState(tenantDataById?.recipientsEmail || []);
  const [currentEmail, setCurrentEmail] = useState("");

  useEffect(() => {
    if (tenantDataById?.recipientsEmail) {
      setEmails(tenantDataById.recipientsEmail);
    }
  }, [tenantDataById]);

  const validateEmail = (email) => {
    const emailRegex =
      /^(?!\s)(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?!\s)$/i;
    return emailRegex.test(email);
  };

  const handleAddEmail = () => {
    if (emails.length >= 5) {
      setError("You can only add up to 5 recipient’s emails.");
      return;
    }
    if (!currentEmail) {
      setError("Recipient’s Emails cannot be blank.");
      return;
    }
    if (!validateEmail(currentEmail)) {
      setError("Please enter a valid recipient’s emails.");
      return;
    }
    if (emails.includes(currentEmail)) {
      setError("This Recipient’s Emails is already added.");
      return;
    }
    setEmails([...emails, currentEmail]);
    setCurrentEmail("");
    setError(""); // Clear error on successful addition
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
    if (emails.length <= 5) setError(""); // Clear error if below limit after delete
  };

  console.log("erroraaaa",error)
  return (
    <div>
      {loader ? (
        <WrapCard title={id ? "Update Tenant" : "Add New Tenant"}>
          <CommonSpinner size="100px" color={colors.primary.main} />
        </WrapCard>
      ) : (
        <>
          <WrapCard title={id ? "Update Tenant" : "Add New Tenant"}>
            {loader ? (
              <CommonSpinner size="100px" color={colors.primary.main} />
            ) : (
              <>
                <Grid component={"form"} container spacing={2} rowSpacing={3}>
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_name" required>
                      Name
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Name"
                      id="t_name"
                      name="t_name"
                      value={formik.values.t_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_name}
                      error={formik.errors.t_name}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_email" required>
                      Email ID
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      inputProps={{
                        autoComplete: "off",
                        required: true,
                      }}
                      type="email"
                      placeholder="Enter Email ID"
                      id="t_email"
                      name="t_email"
                      value={formik.values.t_email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_email}
                      error={formik.errors.t_email}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_password" required={!id}>
                      Create Password
                    </FormLabel>
                    <SuiPasswordInput
                      index={1}
                      attr={{
                        name: "t_password",
                        placeholder: "Enter Password",
                      }}
                      value={formik.values.t_password}
                      onBlur={formik.handleBlur}
                      getValue={formik.handleChange}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_password}
                      error={formik.errors.t_password}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_confirm_password" required={!id}>
                      Confirm Password
                    </FormLabel>
                    <SuiPasswordInput
                      index={1}
                      attr={{
                        name: "t_confirm_password",
                        placeholder: "Re-enter Password",
                      }}
                      value={formik.values.t_confirm_password}
                      onBlur={formik.handleBlur}
                      getValue={formik.handleChange}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_confirm_password}
                      error={formik.errors.t_confirm_password}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_company_name" required>
                      Company Name
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Company Name"
                      id="t_company_name"
                      name="t_company_name"
                      value={formik.values.t_company_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_company_name}
                      error={formik.errors.t_company_name}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_industry_name" required>
                      Industry
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Industry"
                      id="t_industry_name"
                      name="t_industry_name"
                      value={formik.values.t_industry_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_industry_name}
                      error={formik.errors.t_industry_name}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_contact_number" required>
                      Contact Number
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Contact number"
                      id="t_contact_number"
                      name="t_contact_number"
                      value={formik.values.t_contact_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_contact_number}
                      error={formik.errors.t_contact_number}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_company_address" required>
                      Company Address
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Company Address"
                      id="t_company_address"
                      name="t_company_address"
                      value={formik.values.t_company_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_company_address}
                      error={formik.errors.t_company_address}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_timezone">Timezone</FormLabel>
                    <AccountTypeDropdown
                      data={timezone}
                      name="t_timezone"
                      value={formik.values.t_timezone}
                      setValue={formik.handleChange}
                      placeHolder="Select Timezone"
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_timezone}
                      error={formik.errors.t_timezone}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_country">Country</FormLabel>
                    <AccountTypeDropdown
                      data={country}
                      name="t_country"
                      value={formik.values.t_country}
                      setValue={formik.handleChange}
                      placeHolder="Select Country"
                      onBlur={formik.handleBlur}
                      formik={formik}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_country}
                      error={formik.errors.t_country}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_state">State</FormLabel>
                    <AccountTypeDropdown
                      data={statesByCountry}
                      name="t_state"
                      value={formik.values.t_state}
                      setValue={formik.handleChange}
                      placeHolder="Select State"
                      onBlur={formik.handleBlur}
                      disabled={formik.values.t_country ? false : true}
                      formik={formik}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_state}
                      error={formik.errors.t_state}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_city">City</FormLabel>
                    <AutocompleteSingleSelect
                      id="t_city"
                      value={formik.values.t_city_name}
                      setValue={(value) => {
                        console.log("value", value);
                        formik.setFieldValue("t_city_name", value?.name);
                        formik.setFieldValue("t_city", value?.id);
                      }}
                      noOptionsText="No options"
                      placeholder="Select City"
                      isLoading={false}
                      disabled={formik.values.t_state ? false : true}
                      options={cityByState}
                      formik={formik}
                    />
                    {/* <AccountTypeDropdown
                      name="t_city"
                      data={cityByState}
                      value={formik.values.t_city}
                      setValue={formik.handleChange}
                      placeHolder="Select City"
                      onBlur={formik.handleBlur}
                      disabled={formik.values.t_state ? false : true}
                      formik={formik}
                    /> */}
                    <ErrorMessageComponent
                      touched={formik.touched.t_city}
                      error={formik.errors.t_city}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_subscription_id" required>
                      Subscription Plan
                    </FormLabel>
                    <AccountTypeDropdown
                      data={plans}
                      name="t_subscription_id"
                      value={formik.values.t_subscription_id}
                      setValue={formik.handleChange}
                      placeHolder="Select Subscription Plan"
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_subscription_id}
                      error={formik.errors.t_subscription_id}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_email">Recipient’s Emails</FormLabel>
                    <SuiInput
                      customClass={classes.inputBase}
                      inputSettungs={{ autoComplete: "off", required: true }}
                      inputProps={{
                        sx: {
                          border: "none",
                          borderRadius: 0,
                          ...inputProps,
                          width: {
                            md: "800px !important",
                            lg: "430px !important",
                            xs: "430px !important",
                            sm: "400px !important",
                          },
                        },
                      }}
                      type="email"
                      placeholder="Enter Recipient’s Emails"
                      id="t_recipients_email"
                      name="t_recipients_email"
                      value={currentEmail}
                      onChange={(e) => setCurrentEmail(e.target.value)}
                      onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
                      autoComplete="off"
                      // value={formik.values.t_email}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      withIcon={{
                        // marginLeft:"100px",
                        baseClass: "outlined",
                        color: "info",
                        svg: (
                          <AddIcon
                            onClick={handleAddEmail}
                            style={{
                              // marginLeft: "400px",
                              border: "1px solid #1264E2",
                              borderRadius: "100%",
                              backgroundColor: "#1264E2",
                              color: "white",
                            }}
                          />
                        ),
                        direction: "right",
                        // onClickIcon: () => setShowPass(!showPass),
                      }}
                    />
                 
                    {/* <ErrorMessageComponent
                      touched={formik.touched.t_email}
                      error={formik.errors.t_email}
                    /> */}
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {emails.map((email, index) => (
                        <Chip
                          key={index}
                          label={email}
                          onDelete={() => handleDeleteEmail(email)}
                          color="primary"
                        />
                      ))}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_name" required>
                      API Key
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Api Key"
                      id="t_api_key"
                      name="t_api_key"
                      value={formik.values.t_api_key}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.t_api_key}
                      error={formik.errors.t_api_key}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!isCalendly && (
                      <Grid
                        container
                        pb={2}
                        pt={4}
                        columnSpacing={2}
                        rowSpacing={1}
                      >
                        <Grid item>
                          <SuiButton
                            size="small"
                            component={Link}
                            to="/tenant"
                            variant="outlined"
                            buttonColor="dark"
                            sx={{ px: "1rem", py: "0.4rem" }}
                          >
                            Cancel
                          </SuiButton>
                        </Grid>
                        <Grid item>
                          <SuiButton
                            type="submit"
                            size="small"
                            buttonColor="info"
                            sx={{ px: "1rem" }}
                            onClick={formik.handleSubmit}
                          >
                            {id ? (
                              updateLoader ? (
                                <CircularProgress size="20px" color="inherit" />
                              ) : (
                                "Update"
                              )
                            ) : createLoader ? (
                              <CircularProgress size="20px" color="inherit" />
                            ) : (
                              "Create"
                            )}
                          </SuiButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </WrapCard>
          {planType?.includes("calendly") && (
            <WrapCardCalendly
              title="Calendly Integration Details"
              borderBottom="0px"
              button="Add"
              onClick={handleAddPair}
              disabled={calendlyPairs.length >= 5}
            >
              {calendlyPairs.map((pair, index) => (
                <Grid container spacing={2} rowSpacing={3} key={index} mt={1}>
                  <Grid item xs={12} lg={6}>
                    <FormLabel
                      htmlFor={`calendlyPairs[${index}].name`}
                    >
                      Name
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Name"
                      id={`calendlyPairs[${index}].name`}
                      name={`calendlyPairs[${index}].name`}
                      value={pair.name}
                      onChange={(e) =>
                        handlePairChange(index, "name", e.target.value)
                      }
                      // onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                        error={formik.errors.calendlyPairs?.[index]?.name}
                        touched={formik.touched.calendlyPairs?.[index]?.name}
                    />
                    {/* <ErrorMessageComponent
                      touched={
                        formik.touched.calendlyPairs &&
                        formik.touched.calendlyPairs?.[index]?.name
                      }
                      error={
                        formik.errors.calendlyPairs &&
                        formik.errors.calendlyPairs[index]?.name
                      }
                    /> */}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel
                      htmlFor={`calendlyPairs[${index}]?.token`}
                    >
                      Access Token
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Access Token"
                      id={`calendlyPairs[${index}]?.token`}
                      name={`calendlyPairs[${index}]?.token`}
                      value={pair?.token}
                      onChange={(e) =>
                        handlePairChange(index, "token", e.target.value)
                      }
                      
                      
                      // onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                        error={formik.errors.calendlyPairs?.[index]?.token}
                        touched={formik.touched.calendlyPairs?.[index]?.token}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    rowSpacing={1}
                    gap={2}
                    alignItems="center"
                    sx={{ display: "flex" }}
                  >
                    {isCalendly && calendlyPairs.length > 1 && (
                      <SuiButton
                        size="small"
                        variant="outlined"
                        buttonColor="dark"
                        sx={{
                          px: "1rem",
                          py: "0.4rem",
                          marginRight: "4px !important",
                        }}
                        D32F2F
                        onClick={() => handleRemovePair(index)}
                      >
                        Remove
                      </SuiButton>
                    )}
                    <SuiButton
                      size="small"
                      disabled={!pair?.token || !pair.name}
                      buttonColor="info"
                      sx={{
                        fontWeight: "500",
                        px: "12px",
                        backgroundColor: "#161740 !important",
                      }}
                      onClick={() =>
                        handleTestToken(
                          formik.values.calendlyPairs[index]?.token,
                          index
                        )
                      }
                    >
                      {loadingIndex === index ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Test"
                      )}
                    </SuiButton>
                    {error[index]?.type === "failed" && (
                      <SuiTypography
                        fontWeight="500"
                        sx={{
                          marginLeft: "14px !important",
                          fontSize: "14px !important",
                          display: "flex !important",
                          alignItems: "center !important",
                          color: "#D32F2F !important",
                        }}
                      >
                        {error[index]?.message}{" "}
                        <CloseIcon
                          sx={{
                            color: "#D32F2F",
                            marginLeft: "8px",
                            height: "20px",
                            width: "20px",
                          }}
                        />{" "}
                      </SuiTypography>
                    )}
                    {error[index]?.type === "success" && (
                      <SuiTypography
                        fontWeight="500"
                        sx={{
                          marginLeft: "14px !important",
                          fontSize: "14px !important",
                          display: "flex !important",
                          alignItems: "center !important",
                          color: "#86CC27 !important",
                        }}
                      >
                        {" "}
                        {error[index]?.message}{" "}
                        <CheckIcon
                          sx={{
                            color: "#86CC27",
                            marginLeft: "8px",
                            height: "20px",
                            width: "20px",
                          }}
                        />{" "}
                      </SuiTypography>
                    )}
                  </Grid>
                  {typeof formik.errors.calendlyPairs && (
                    <Grid item xs={12} lg={6}>
                    {typeof formik.errors.calendlyPairs
                        === 'string' && (
                        <ErrorMessageComponent
                            error={formik.errors.calendlyPairs}
                            touched={formik.touched.calendlyPairs}
                        />
                      )}
                  </Grid>
                  )}
                </Grid>
              ))}
              <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
                <Grid item>
                  <SuiButton
                    size="small"
                    component={Link}
                    to="/tenant"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item>
                  <SuiButton
                    type="submit"
                    size="small"
                    buttonColor="info"
                    sx={{ px: "1rem" }}
                    onClick={formik.handleSubmit}
                  >
                    {id ? (
                      updateLoader ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Update"
                      )
                    ) : createLoader ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Create"
                    )}
                  </SuiButton>
                </Grid>
              </Grid>
            </WrapCardCalendly>
          )}
        </>
      )}
    </div>
  );
};

export default CreateTenant;
