import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import {
  TenantChatBotTableHeader,
  TenantTemplateTableHeader,
  TenantChatBotTableHeaderMearge,
} from "./data";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "Container/Icons/EditIcon";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import SuiDialog from "components/SuiDialog";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import useNavigateSearch from "hooks/useNavigateSearch";
import { useDownloadDataMutation } from "app/features/api/AuthApi";
import { appendZero } from "helper";
import { format } from "date-fns";
import { setID } from "app/features/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCustomerBulkDiscountMutation } from "app/features/api/CustomerApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import * as Yup from "yup";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import UpArrow from "Container/UpArrow";
import { useDeleteTemplateMutation } from "app/features/api/AuthApi";
import SearchIcon from "@mui/icons-material/Search";
import DialogBoxForEncryptedCode from "components/DialogBoxEncryptedCode";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useGetTenantTemplateInfoMutation } from "app/features/api/AuthApi";
import { Padding } from "@mui/icons-material";
import boxShadow from "assets/theme/functions/boxShadow";
import { useMergeTemplatesMutation } from "app/features/api/AuthApi";
import { useCreateMergeTemplateMutation } from "app/features/api/AuthApi";
import { useAddTemplateMutation } from "app/features/api/AuthApi";
export let apiController = new AbortController();

const TenantTemplateTable = ({}) => {
  const { id } = useParams();
  const [count, setCount] = useState(0);
  const suiSnackbar = useSuiSnackbar();
  const [checkedItems, setCheckedItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [customerInfo, setCustomerInfo] = useState("");
  const [searchParams, setSearchParams] = useState("");
  const [openCodePreview, setOpenCodePreview] = useState(false);
  const [previewCodeDialogText, setPreviewCodeDialogText] = useState("");
  let page = new URLSearchParams(window.location.search).get("page");
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [getTenantTemplateInfo, { isLoading }] =
    useGetTenantTemplateInfoMutation();
  const [downloadData] = useDownloadDataMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [customerBulkDiscount] = useCustomerBulkDiscountMutation();
  const [deleteTemplate, { isLoading: deleteLoader }] =
    useDeleteTemplateMutation();
  const [paginationObject, setPaginationObject] = useState({
    currentPage: 0,
    resultsPerPage: 0,
    totalPages: 0,
    totalTemplates: 0,
  });

  const { permission } = useSelector((state) => state.auth);
  const loginType = localStorage.getItem("type");
  // useEffect(() => {
  //   if(permission && loginType === 'admin' &&!permission?.includes("chatbot:read")) {
  //     navigate('/profile')
  //   }
  // },[permission])

  const [createMergeTemplate] = useCreateMergeTemplateMutation();

  const [mergeTemplates] = useMergeTemplatesMutation();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [errorName, setErrorName] = useState();
  const [AddTemplate] = useAddTemplateMutation();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handleAddTemplateSelect = (templateId) => {
    setSelectedTemplate((prev) => (prev === templateId ? null : templateId)); // Toggle selection
  };

  const handleAddRefreshTemplate = () => {
    setSelectedTemplate(null);
    setAddTanetOpen(false);
  };

  const addformik = useFormik({
    initialValues: {
      selectedTemplates: [],
    },
    onSubmit: async (values) => {
      const token = localStorage.getItem("auth_token_admin");
      // const formattedTemplates = selectedTemplates.map(
      //   (templateId, index) => `${index + 1}:${templateId}`
      // );
      const body = {
        id,
        token,
        templateId: selectedTemplate,
      };

      try {
        const tamplateId = await AddTemplate(body);
        suiSnackbar(tamplateId.data.message, "success");
        navigate(
          `/chatbot-template/edit-template/${tamplateId?.data?.data?.templateId}`
        );
      } catch (error) {
        console.error("Error merging templates:", error);
      }
    },
  });
  useEffect(() => {
    // Fetch templates from the API when component mounts
    const fetchTemplates = async (id, searchString = "") => {
      const token = localStorage.getItem("auth_token_admin");
      let body = {
        id,
        token,
        searchName,
      };
      try {
        const response = await mergeTemplates(body);
        if (response?.data) {
          setTemplates(response.data.data);
          setErrorName();
        } else {
          setTemplates([]);
          setErrorName(response.error.data.message);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates(id, searchName);
  }, [mergeTemplates, searchName, errorName]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedTemplates(
      isChecked ? templates.map((template) => template.id) : []
    );
  };

  const handleTemplateSelect = (templateId) => {
    const isSelected = selectedTemplates.includes(templateId);
    const newSelectedTemplates = isSelected
      ? selectedTemplates.filter((id) => id !== templateId)
      : [...selectedTemplates, templateId];

    setSelectedTemplates(newSelectedTemplates);
    formikTemplate.setFieldValue("selectedTemplates", newSelectedTemplates);
  };

  const handleResetMergeTamplete = () => {
    setSelectedTemplates([]);
    setSelectAll(false);
    setMeargeTanetOpen(false);
  };
  const getSelectionOrder = (id) => {
    return selectedTemplates.indexOf(id) + 1;
  };

  const formikTemplate = useFormik({
    initialValues: {
      selectedTemplates: [],
    },
    onSubmit: async (values) => {
      const token = localStorage.getItem("auth_token_admin");
      const formattedTemplates = selectedTemplates.map(
        (templateId, index) => `${index + 1}:${templateId}`
      );
      const body = {
        id,
        token,
        templateId: formattedTemplates,
      };

      try {
        const tamplateId = await createMergeTemplate(body);
        if(tamplateId.error){
        suiSnackbar(tamplateId.error.data.message, "error");

        }else{
          suiSnackbar(tamplateId.data.message, "success");
          navigate(
            `/chatbot-template/edit-template/${tamplateId?.data?.data?.templateId}`
          );
        }
        
      } catch (error) {
        console.log("error",error)

      }
    },
  });

  const doCancel = () => {
    apiController.abort();
    apiController = new AbortController();
  };

  function getPageRangeText(paginationInfo) {
    const { currentPage, resultsPerPage, totalTemplates } = paginationInfo;
    const startIndex = (currentPage - 1) * resultsPerPage + 1;
    let endIndex = currentPage * resultsPerPage;
    if (endIndex > totalTemplates) {
      endIndex = totalTemplates;
    }
    const text = `Showing ${startIndex} to ${endIndex} of ${totalTemplates} entries`;

    return text;
  }
  const type = localStorage.getItem("type");

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [mergeTanetOpen, setMeargeTanetOpen] = useState(false);
  const [addTanetOpen, setAddTanetOpen] = useState(false);

  const [deleteID, setDeleteID] = useState("");

  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handleDeleteIconClick = async (item) => {
    try {
      const response = await deleteTemplate(item).unwrap();
      if (response.statusCode === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
        navigate(`/tenant-chatbot-template/${id}`);
        const rolesInfo = await getTenantTemplateInfo({
          id,
          params: { page: 1, limit: 10 },
        });
        setSearchParams("");
        setCount(rolesInfo?.data?.data?.pagination?.totalTemplates);
        setCustomerInfo(rolesInfo?.data?.data?.templates);
        setPaginationObject(rolesInfo?.data?.data?.pagination);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const scriptSRC = process.env.REACT_APP_BASE_URL;
  const handleCopyCode = (data) => {
    const textToCopy = `<script src="${scriptSRC}/tenant/main.js/${data}" ></script>`;
    navigator.clipboard.writeText(textToCopy);
    suiSnackbar("Code copied successfully", "success");
  };
  useEffect(() => {
    // This code runs when the component mounts

    return () => {
      var tags = document.getElementsByTagName("script");
      for (var i = tags.length; i >= 0; i--) {
        console.log(tags[i]?.getAttribute("id"));
        if (tags[i] && tags[i].getAttribute("id") === "socket-script") {
          console.log("in script");
          tags[i].parentNode.removeChild(tags[i]);
        }
      }

      var cbwBody = document.querySelector(".cbw__body");
      if (cbwBody) {
        console.log(cbwBody);
        cbwBody.style.display = "none";
        cbwBody.classList.remove("show");
      }
    };
  }, []);

  // FORM DETAIL
  const formik = useFormik({
    initialValues: {
      cus_account_number: "",
      first_name: "",
      last_name: "",
      mobile_num: "",
      email: "",
      account_type: "",
      account_status: "",
      cus_company_name: "",
    },

    enableReinitialize: true,
    onSubmit: () => {
      // handleSubmitForm();
    },
  });

  const discountFormik = useFormik({
    initialValues: {
      sip_trunk: "",
      sip_trunk_margin: 0,
      international_did: "",
      international_did_margin: 0,
      national_did: "",
      national_did_margin: 0,
    },
    // enableReinitialize: true,/
    validationSchema: Yup.object().shape({
      sip_trunk: Yup.string().required("Please select the discount type"),
      international_did: Yup.string().required(
        "Please select the discount type"
      ),
      national_did: Yup.string().required("Please select the discount type"),
      sip_trunk_margin: Yup.number().when("sip_trunk", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value should be at least 1%.")
          .max(100, "SIP Trunk value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value must be at least 1"),
      }),
      international_did_margin: Yup.number().when("international_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value should be at least 1%")
          .max(100, "International DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value must be at least 1"),
      }),
      national_did_margin: Yup.number().when("national_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value should be at least 1%")
          .max(100, "National DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value must be at least 1"),
      }),
    }),
    onSubmit: (values) => {
      handleDiscount(values);
    },
  });
  const handlePageChange = async (event, page) => {
    setIsChecked(true);
    setCurrentPage(page);
    const response = await getTenantTemplateInfo({
      id,
      params: { search: searchParams, page: page, limit: 10 },
    });
    setCustomerInfo(response?.data?.data?.templates);
    setCount(response?.data?.data?.pagination?.totalTemplates);
    setPaginationObject(response?.data?.data?.pagination);
    setPerPage(10);
    setCurrentPage(page);
    setIsChecked(false);
  };
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    if (formik.values.mobile_num) {
      urlParamss.cus_phone_number = formik.values.mobile_num;
    }
    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (
      formik.values.account_type == "pre_paid" ||
      formik.values.account_type == "post_paid"
    ) {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else if (formik.values.account_type == "post_paid") {
        urlParamss.cus_account_type = 0;
      }
    }
    if (formik.values.account_status === "active") {
      urlParamss.cus_status = 1;
    }
    if (formik.values.account_status === "inactive") {
      urlParamss.cus_status = 0;
    }
    if (formik.values.account_status === "blocked") {
      urlParamss.cus_status = 3;
    }
    if (formik.values.account_status === "restricted") {
      urlParamss.cus_status = 2;
    }
    const response =
      Object.keys(urlParamss)?.length > 0
        ? await downloadData(urlParamss).unwrap()
        : await downloadData().unwrap();
    return response?.data;
  };

  const onDownloadFn = async () => {
    const response = await downloadDetails();
    const downloadData = response?.map((item, i) => {
      let cusAccntStatus;
      if (item.cus_network_status === "1") {
        cusAccntStatus = "ACTIVE";
      } else if (item.cus_network_status === "0") {
        cusAccntStatus = "INACTIVE";
      } else if (item.cus_network_status === "3") {
        cusAccntStatus = "BLOCKED";
      } else if (item.cus_network_status === "2") {
        cusAccntStatus = "RESTRICTED";
      }
      return {
        "Sr.no": appendZero(i + 1),
        USERNUMBER: item?.cus_account_number,
        "EMAIL ID":
          item?.cus_firstname.charAt(0).toUpperCase() +
          item?.cus_firstname.slice(1),
        "LAST NAME":
          item?.cus_lastname.charAt(0).toUpperCase() +
          item?.cus_lastname.slice(1),
        ROLE: item?.cus_account_type === "1" ? "PRE PAID" : " POST PAID",
      };
    });

    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) =>
          csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Customer List(${format(
      new Date(),
      "dd/MM/yyyy | hh:mm a"
    )}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleOpenPreviewDialogBox = (data) => {
    setPreviewCodeDialogText(data);
    setOpenCodePreview(true);
  };
  const rows =
    customerInfo &&
    customerInfo?.map((item) => {
      const isNetworkStatus = item.cus_network_status == 1;
      let accountStatus = "";
      if (item.cus_status == 1) {
        accountStatus = "Active";
      }
      if (item.cus_status == 0) {
        accountStatus = "Inactive";
      }
      if (item.cus_status == 3) {
        accountStatus = "Blocked";
      }
      if (item.cus_status == 2) {
        accountStatus = "restricted";
      }

      const cus_id = item;
      const editRedirect = (item) => {
        console.log("item", item);
        navigate(`/chatbot-template/edit-template/${item}`);
      };
      const openPreview = (item) => {
        const loaderDiv = document.createElement("div");
        loaderDiv.id = "loader";
        loaderDiv.className = "loader-overlay";
        loaderDiv.innerHTML = `<div class="loader-d"></div>`;
        // loaderDiv.style.position = 'fixed';
        // loaderDiv.style.width = '100%';
        // loaderDiv.style.height = '100%';
        // loaderDiv.style.top = 0;
        // loaderDiv.style.left = 0;
        // loaderDiv.style.animation = 'l5 2s infinite linear alternate;'
        document.body.appendChild(loaderDiv);

        const existingScript = document.getElementById("chatboat-script");
        var tags = document.getElementsByTagName("script");
        for (var i = tags.length; i >= 0; i--) {
          //search backwards within nodelist for matching elements to remove
          console.log(tags[i]?.getAttribute("id"));
          if (tags[i] && tags[i].getAttribute("id") === "socket-script") {
            console.log("in script");
            tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
          }
        }
        if (!existingScript) {
          const chatbotScript = document.createElement("script");
          chatbotScript.src = `${process.env.PUBLIC_URL}/chatboat/preview-chatbot.js`; // Load from public folder
          chatbotScript.id = "chatboat-script";
          document.body.appendChild(chatbotScript);
        }
        const socketId = "socket-script";
        const existingSocketScript = document.getElementById(socketId);
        const socketScript = document.createElement("script");
        socketScript.id = socketId;
        setTimeout(() => {
          var cbwBody = document.querySelector(".cbw__chat");
          cbwBody.innerHTML = "";
        }, 1000);

        socketScript.innerHTML = "";
        socketScript.innerHTML += ` 
              function loadChat() {
                  var messageInput = document.getElementById('messageInput');
                  messageInput.value = '' ;
                
                  messageInput.style.height =  '26px';
                  var inputBox = document.querySelector(".cbw__footer");
                  inputBox.classList.remove('show');
                  inputBox.classList.add('hide');
                  var sendBtn = document.querySelector('.cbw__send-btn');
                  sendBtn.classList.remove('transform-div');
                  const message = {answer: 'ecosmob#anaweringlegal@chatbotwidget', preview: true};
                  window.ws.send(JSON.stringify(message));
                  var chat = document.querySelector('.cbw__chat');
                  var loaderContainer = document.createElement('div');
                  loaderContainer.className = 'cbw__receiver-msg-box';
                  loaderContainer.id = 'main-loader-' + window.loaderCount ;
                  loaderContainer.innerHTML = \`
                      <p class="cbw__receiver-msg">
                          <span class="loader"></span>
                      </p>
                  \`;
                  chat.append(loaderContainer);
                  setTimeout(scrollToBottom, 100); // Add delay to ensure scrolling works
                  function scrollToBottom() {
                    var chatDiv = document.querySelector('.cbw__chat');
                    chatDiv.scrollTop = chatDiv.scrollHeight;
                    chatDiv.scrollTo(0,chatDiv.scrollHeight);
                  }
  
                
                  var cbwBody = document.querySelector(".cbw__body");
                    var cbwCloseBtn = document.querySelector(".cbw__close-btn");
                    console.log(cbwCloseBtn);
                    if(cbwCloseBtn) {
                        cbwCloseBtn.addEventListener("click", (event) => {   
                          event.preventDefault();
                          // var chat = document.querySelector('.cbw__chat');
                          const getCurrentPreview = document.getElementsByClassName('preview');  
                          if(getCurrentPreview) {
                            for(let i=0;i<getCurrentPreview?.length;i++) {
                              getCurrentPreview[i].style.pointerEvents = 'auto';
                            }
                          } 
                        cbwBody.classList.remove('show');
                        var messageInput = document.getElementById('messageInput');
                        messageInput.value = '' ;
                        messageInput.style.height =  '26px';
                        cbwBody.style.display = 'none';
                        var inputBox = document.querySelector(".cbw__footer");
                        inputBox.classList.remove('show');
                        inputBox.classList.add('hide');
                        var sendBtn = document.querySelector('.cbw__send-btn');
                        sendBtn.classList.remove('transform-div');
                        window.ws.close();
  
                    });
                  }
                }
                window.socketTemplateCode = \`${item?.templateCodeEncrpyt}-preview-admin\`
                window.code = \`${item?.templateCodeEncrpyt}\`;
                window.socketPath = 'wss://chatapi.answeringlegal.com';
                console.log(window.socketPath);
                window.ws = new WebSocket(window.socketPath, window.socketTemplateCode);
                window.ws.onopen = () => {
                  var divToRemove = document.getElementById('loader');
                  if (divToRemove) {
                    // Remove the selected div from the body
                      divToRemove.parentNode.removeChild(divToRemove);
                  }
                  var cbwBody = document.querySelector(".cbw__body");
                  cbwBody.classList.add('show');
                  cbwBody.style.display = 'flex'; 
                  var chat = document.querySelector('.cbw__chat');
                  if(chat) {
                    chat.style.display = 'flex';
                    chat.innerHtml = '';
                  }
                  var chatcalendarDiv = document.querySelector('.cbw__chat__calendar');
                  if(chatcalendarDiv) {
                    chatcalendarDiv.style.display = 'none';
                    chatcalendarDiv.innerHtml = '';
  
                  }
                  const getCurrentPreview = document.getElementsByClassName('preview');
                  if(getCurrentPreview) {
                    for(let i=0;i<getCurrentPreview?.length;i++) {
                      getCurrentPreview[i].style.pointerEvents = 'none';
                    }
                  }
                  window.loaderCount = 1;
                  loadChat();
                };
            
                window.ws.onmessage = (event) => {
                  setTimeout(() => {
                      const message = JSON.parse(event.data);
                      const loaderId = 'main-loader-' + window.loaderCount
                      document.getElementById(loaderId)?.classList?.add('hide');
                      if(message?.question_id) {
                          window.questionInfo = message;
                          nextQuestionPrepare(message);
                      } else if(message?.answerType == 'Lead_Collection_Form') {
                          window.questionInfo = message;
                          nextQuestionPrepare(message); 

                      } else if(message?.responseType === 'error') {
                        document.getElementById(loaderId)?.classList?.add('hide');
                        var chat = document.querySelector('.cbw__chat');
                        chat.innerHTML = '';
                        var questionContainer = document.createElement('div');
                        questionContainer.className = '';
                        questionContainer.style.color = 'red';
                        questionContainer.style.textAlign = 'center';
                        questionContainer.style.width = '95%';
                        questionContainer.style.fontSize = '13px';
                        questionContainer.id = 'error';
                        questionContainer.innerHTML += \`<div class="">
                            <p class="">
                            \${message?.question}
                            </p>
                        </div>\`;
                        if(chat) {
                          chat.append(questionContainer);
                        }
                        setTimeout(scrollToBottom, 100);
                        var inputBox = document.querySelector(".cbw__footer");
                        inputBox.classList.remove('show');
                        inputBox.classList.add('hide');
                        var messageInput = document.getElementById('messageInput');
                        messageInput.value = '' ;
                    }
                  }, 1000);
                };
                window.ws.onclose = () => {
                    var chat = document.querySelector('.cbw__chat');
                    chat.innerHTML = '';
                    var questionContainer = document.createElement('div');
                    questionContainer.className = '';
                    questionContainer.style.color = 'grey';
                    questionContainer.style.textAlign = 'center';
                    questionContainer.style.width = '100%';
                    questionContainer.style.fontSize = '13px';
                    questionContainer.id = 'error';
                    questionContainer.innerHTML += \`<div class="">
                        <p class="">
                        Your chat session has ended. If you wish to continue the chat,<a href="javascript:void(0);" class="cbw__restart_btn"> click here.</a>
                        </p>
                    </div>\`
                    if(chat) {
                      chat.append(questionContainer);
                    }
                    var restartBtn = document.querySelector('.cbw__restart_btn');
                    if(restartBtn) {
                        restartBtn.addEventListener("click", () => {
                            loadChat();
                        });
                    }
                    
                    setTimeout(scrollToBottom, 100);
                    var inputBox = document.querySelector(".cbw__footer");
                    inputBox.classList.remove('show');
                    inputBox.classList.add('hide');
                    console.log('Disconnected from WebSocket server');
                };
                window.ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };`;
        setTimeout(() => {
          document.body.appendChild(socketScript);
          const customEvent = new CustomEvent("templeteCodeGetEvent", {
            detail: item,
          });
          document.dispatchEvent(customEvent);
        }, 1000);
      };
      dispatch(setID(cus_id));
      return (
        <>
          <TableRow>
            <TableCell sx={{ minWidth: "170px" }}>
              <Tooltip title="Preview" arrow className="preview">
                <IconButton onClick={() => openPreview(item)}>
                  <UpArrow />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={item?.templateName || "-"} arrow>
                <span>{item?.templateName || "-"}</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title="Subscription Code" arrow>
                <span>
                  {/* {type === 'tenant' ?
                      <IconButton onClick={() => handleCopyCode(item.templateCodeEncrpyt)} >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      :
                      item?.cb_SubscriptionPlan?.subscriptionName || '-'} */}
                  <IconButton
                    onClick={() => handleCopyCode(item.templateCodeEncrpyt)}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
            <TableCell
              sx={{ display: "flex", minWidth: "132px", whiteSpace: "nowrap" }}
            >
              {type === "tenant" ? (
                <>
                  <Tooltip title="Edit" arrow>
                    <IconButton onClick={() => editRedirect(item.id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton onClick={() => deleteModal(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Steps" arrow>
                    <IconButton
                      onClick={() =>
                        handleOpenPreviewDialogBox(item.templateCodeEncrpyt)
                      }
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  {permission ? (
                    <>
                      {permission.includes("chatbot:create") ||
                      permission.includes("chatbot:delete") ? (
                        <>
                          {permission.includes("chatbot:create") && (
                            <Tooltip title="Edit" arrow>
                              <IconButton onClick={() => editRedirect(item.id)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permission.includes("chatbot:delete") && (
                            <Tooltip title="Delete" arrow>
                              <IconButton onClick={() => deleteModal(item.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        </>
      );
    });

  const getCustomerOnload = async () => {
    try {
      const response = await getTenantTemplateInfo({
        id,
        params: {
          limit: 10,
          page: 1,
        },
      }).unwrap();
      setPerPage(10);
      setCurrentPage(1);
      setCount(response?.data?.pagination?.totalTemplates);
      setCustomerInfo(response.data?.templates);
      setPaginationObject(response?.data?.pagination);
      if (checkedItems?.length > 0) {
        setCheckedItems([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsChecked(false);
    }
  };
  const handleDiscount = async (values) => {
    const body = {
      customer_ids: checkedItems,
      discount: [
        {
          discount_product_name: "SIP Trunk",
          discount_type: values.sip_trunk ? values.sip_trunk : "Fixed",
          discount_value: Number(values.sip_trunk_margin),
        },
        {
          discount_product_name: "International DID",
          discount_type: values.international_did
            ? values.international_did
            : "Fixed",
          discount_value: Number(values.international_did_margin),
        },
        {
          discount_product_name: "National DID",
          discount_type: values.national_did ? values.national_did : "Fixed",
          discount_value: Number(values.national_did_margin),
        },
      ],
    };
    try {
      const response = await customerBulkDiscount(body).unwrap();
      suiSnackbar(response.message, "success");
      setOpen(false);
      setCheckedItems([]);
      discountFormik.handleReset();
    } catch (error) {
      suiSnackbar(error.data.message, "error");
    }
  };
  useEffect(() => {
    getCustomerOnload();
  }, []);

  const handleSearch = async (e) => {
    setSearchParams(e.target.value);
    if (e.target.value.length > 2) {
      setIsChecked(true);
      try {
        const response = await getTenantTemplateInfo({
          id,
          params: { search: e.target.value, page: 1, limit: 10 },
        });
        setCount(response?.data?.data?.pagination?.totalTemplates);
        setCustomerInfo(response?.data?.data?.templates);
        setPaginationObject(response?.data?.data?.pagination);
        setPerPage(10);
        setCurrentPage(1);
      } catch (error) {
        console.log(error);
      } finally {
        setIsChecked(false);
      }
    }

    if (e.target.value.length === 0) {
      setIsChecked(true);
      const response = await getTenantTemplateInfo({
        id,
        params: { search: e.target.value, page: 1, limit: 10 },
      });
      setCustomerInfo(response?.data?.data?.templates);
      setCurrentPage(1);
      setCount(response?.data?.data?.pagination?.totalTemplates);
      setPaginationObject(response?.data?.data?.pagination);
      setIsChecked(false);
    }
  };

  return (
    <>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <SuiBox sx={{ display: "flex", alignItems: "center" }}>
            <Box component={Link} to={"/tenant"} onClick={doCancel}>
              <ArrowBackIosIcon
                style={{
                  width: "2em",
                  height: "1.3em",
                  marginLeft: "10px",
                  marginRight: "-18px",
                  marginTop: "6px",
                  cursor: "pointer",
                  color: "rgb(22, 23, 64)",
                }}
              />
            </Box>

            <TableToolbar title={"Chatbot Template Listing"}>
              {/* <SuiButton
                    component={Link}
                    size="small"
                    buttonColor="info"P
                    sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                    to={"/tenant"}
                    onClick={doCancel}
                  >
                    Backb
            </SuiButton> */}
            </TableToolbar>
          </SuiBox>

          <hr
            style={{ marginTop: 0, marginBottom: 0, borderColor: "#E9E9E966" }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }} m={2}>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Search"
              name="discount_type"
              value={searchParams}
              onChange={handleSearch}
              customClass="inputWithIcon"
              withIcon={{
                baseClass: "outlined",
                svg: (
                  <SearchIcon
                    sx={{
                      color: "#999999",
                      width: "16px",
                      height: "14px",
                      paddingRight: 0,
                    }}
                  />
                ),
                direction: "left",
              }}
            />
            {/* {type === 'tenant' ?
                  <> */}
  <div>
            {type === "admin" && (
              <>
                {templates.length > 0 && (
                  <SuiButton
                    onClick={() => setMeargeTanetOpen(true)}
                    size="small"
                    sx={{
                      fontWeight: "500",
                      px: "12px",
                      ml: 2,
                      backgroundColor: "#161740 !important",
                    }}
                  >
                    Merge Templates
                  </SuiButton>
                )}
                <SuiButton
                  size="small"
                  buttonColor="info"
                  sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                  onClick={() => setAddTanetOpen(true)}
                >
                  Add Template
                </SuiButton>
              </>
            )}
            </div>
          </Box>

          <EnhancedTable
            // tableMinWidth={1000}
            tablehead={
              type === "tenant"
                ? TenantChatBotTableHeaderMearge
                : TenantTemplateTableHeader
            }
            rowsData={rows}
            isLoading={isChecked}
            overFlow="hidden !important"
          />
          <SuiDialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            haveCloseIcon
          >
            <DialogContent
              sx={{
                fontWeight: "semiBold",
              }}
            >
              Are you sure you want delete this chatbot template?
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container justifyContent={"end"} spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        onClick={() => setOpenModal(false)}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                      >
                        Cancel
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        buttonColor="info"
                        fullWidth
                        onClick={() => handleDeleteIconClick(deleteID)}
                      >
                        {deleteLoader ? (
                          <CircularProgress size="20px" color="inherit" />
                        ) : (
                          "Delete"
                        )}
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>

          <DialogBoxForEncryptedCode
            open={openCodePreview}
            handleClose={() => setOpenCodePreview(false)}
            text={previewCodeDialogText}
          />

          {!isChecked && customerInfo?.length > 0 && (
            <Grid
              container
              p={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "space-between" }}
              alignItems="center"
            >
              <Grid item>
                <SuiTypography fontWeight="light" fontSize={14} variant="h6">
                  {getPageRangeText(paginationObject)}{" "}
                </SuiTypography>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>

        <SuiDialog
          open={mergeTanetOpen}
          onClose={() => handleResetMergeTamplete()}
          haveCloseIcon
        >
          {/* <form onSubmit={formikTamplet.handleSubmit}> */}
          <DialogContent>
            <form onSubmit={formikTemplate.handleSubmit}>
              <SuiBox
                sx={{
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #E0E0E0",
                }}
              >
                <SuiBox sx={{ padding: "10px 10px 10px 25px" }}>
                  <SuiTypography sx={{ fontSize: "18px", fontWeight: "700" }}>
                    Chatbot Template Listing
                  </SuiTypography>
                  <SuiBox sx={{ marginTop: "20px", width: "300px" }}>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off" }}
                      type="text"
                      placeholder="Search"
                      name="search_Tamplet_Name"
                      onChange={(e) => setSearchName(e.target.value)}
                      customClass="inputWithIcon"
                      withIcon={{
                        baseClass: "outlined",
                        svg: (
                          <SearchIcon
                            sx={{
                              color: "#999999",
                              width: "16px",
                              height: "14px",
                              paddingRight: 0,
                            }}
                          />
                        ),
                        direction: "left",
                      }}
                    />
                  </SuiBox>
                </SuiBox>
                <Paper
                  elevation={3}
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    boxShadow: "none",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        marginBottom: "0px !important",
                        borderBottom: "2px solid #E4E9F0",
                        padding: "10px 10px 16px 25px",
                        marginRight: "0px",
                      }}
                      control={
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          color="secondary"
                          style={{ borderRadius: "0 !important" }}
                          sx={{
                            "& svg": {
                              borderRadius: "1px !important",
                            },
                            "& .MuiIconButton-root": {
                              borderRadius: "1px !important",
                            },
                            "&.MuiCheckbox-root": {
                              borderRadius: "5px !important",
                            },
                            "& .css-1me0qvy-MuiButtonBase-root-MuiCheckbox-root":
                              {
                                borderRadius: "1px !important",
                              },
                          }}
                          // onChange={() => handleCheckboxChange(id, id)}
                        />
                      }
                      label={
                        <SuiTypography
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            marginLeft: "10px",
                          }}
                        >
                          TEMPLATE NAME
                        </SuiTypography>
                      }
                    />
                    <div
                      style={{
                        overflowY: "scroll",
                        height: errorName ? "40px" : "260px",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "#D3D3D3 #ffffff",
                        // padding:"15px"
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      {templates.map((template) => (
                        <Box
                          key={template.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "relative",
                            border: selectedTemplates.includes(template.id)
                              ? "1px solid #A6C4F4"
                              : "1px solid transparent",
                            padding: "10px",
                            width: "100%",
                            borderBottom: selectedTemplates.includes(
                              template.id
                            )
                              ? "1px solid #"
                              : "1px solid #E4E9F0",
                            backgroundColor: selectedTemplates.includes(
                              template.id
                            )
                              ? "#1264E20D"
                              : "",
                          }}
                        >
                          <FormControlLabel
                            sx={{}}
                            control={
                              <Checkbox
                                checked={selectedTemplates.includes(
                                  template.id
                                )}
                                onChange={() =>
                                  handleTemplateSelect(template.id)
                                }
                                color="secondary"
                                style={{ borderRadius: "0 !important" }}
                                sx={{
                                  "& svg": {
                                    borderRadius: "1px !important",
                                  },
                                  "& .MuiIconButton-root": {
                                    borderRadius: "1px !important",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: "5px !important",
                                  },
                                  "& .css-1me0qvy-MuiButtonBase-root-MuiCheckbox-root":
                                    {
                                      borderRadius: "1px !important",
                                    },
                                }}
                              />
                            }
                            label={template.templateName}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                color: "#323232",
                                fontWeight: "500",
                                fontSize: "15px",
                                marginLeft: "15px",
                                // any other styling you need
                              },
                            }}
                          />
                          {selectedTemplates.includes(template.id) && (
                            <Box
                              sx={{
                                position: "absolute",
                                right: 16,
                                top: "50%",
                                transform: "translateY(-50%)",
                                backgroundColor: "#161740",
                                color: "#fff",
                                borderRadius: "50%",
                                width: 24,
                                height: 24,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {getSelectionOrder(template.id)}
                            </Box>
                          )}
                        </Box>
                      ))}
                      <p
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "400",
                          padding: "10px",
                        }}
                      >
                        {errorName}
                      </p>
                    </div>
                  </FormGroup>
                </Paper>
              </SuiBox>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <Button
                        size="small"
                        sx={{
                          px: "1rem",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#999999",
                          borderColor: "#999999 !important",
                        }}
                        onClick={() => handleResetMergeTamplete()}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        disabled={selectedTemplates?.length < 2}
                        size="small"
                        sx={{ px: "1rem", fontSize: "16px", fontWeight: "500" }}
                        buttonColor="info"
                        fullWidth
                        type="submit"
                      >
                        Merge Templates
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </SuiDialog>

        {/* add tamplete */}

        <SuiDialog
          open={addTanetOpen}
          onClose={() => handleAddRefreshTemplate()}
          haveCloseIcon
        >
          <DialogContent>
            <form onSubmit={addformik.handleSubmit}>
              <SuiBox
                sx={{
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #E0E0E0",
                }}
              >
                {/* <Paper
                  elevation={3}
                  style={{ width: "100%", marginTop: "10px" }}
                > */}

                <SuiBox sx={{ padding: "16px 16px 16px 26px" }}>
                  <SuiTypography sx={{ fontSize: "18px", fontWeight: "700" }}>
                    Chatbot Template Listing
                  </SuiTypography>
                  <SuiBox sx={{ marginTop: "15px", width: "300px" }}>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off" }}
                      type="text"
                      placeholder="Search"
                      name="discount_type"
                      onChange={(e) => setSearchName(e.target.value)}
                      customClass="inputWithIcon"
                      withIcon={{
                        baseClass: "outlined",
                        svg: (
                          <SearchIcon
                            sx={{
                              color: "#999999",
                              width: "16px",
                              height: "14px",
                              paddingRight: 0,
                            }}
                          />
                        ),
                        direction: "left",
                      }}
                    />
                  </SuiBox>
                </SuiBox>

                <FormGroup>
                  <SuiTypography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      borderBottom: "2px solid #E4E9F0",
                      padding: "16px 16px 16px 28px",
                    }}
                  >
                    TEMPLATE NAME
                  </SuiTypography>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: errorName ? "40px" : "232px",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#D3D3D3 #ffffff",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    {templates.map((template) => (
                      <Box
                        key={template.id}
                        onClick={() => handleAddTemplateSelect(template.id)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          border:
                            selectedTemplate === template.id
                              ? "1px solid #A6C4F4"
                              : "1px solid transparent",
                          padding: "10px",
                          width: "100%",
                          borderBottom:
                            selectedTemplate === template.id
                              ? "1px solid #"
                              : "1px solid #E4E9F0",
                          backgroundColor:
                            selectedTemplate === template.id ? "#1264E20D" : "",
                        }}
                      >
                        <SuiTypography
                          sx={{ fontSize: "15px", fontWeight: "500" }}
                        >
                          {template.templateName}
                        </SuiTypography>
                      </Box>
                    ))}
                    <p
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "400",
                        padding: "10px",
                      }}
                    >
                      {errorName}
                    </p>
                  </div>
                </FormGroup>
                {/* </Paper> */}
              </SuiBox>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm="auto" sx={{ color: "#999999" }}>
                      <Button
                        size="small"
                        sx={{
                          px: "1rem",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#999999 !important",
                          borderColor: "#999999 !important",
                        }}
                        onClick={
                          () => handleAddRefreshTemplate() // Close the modal or perform other actions
                        }
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        disabled={selectedTemplate === null ? true : false}
                        size="small"
                        sx={{ px: "1rem", fontSize: "16px", fontWeight: "500" }}
                        buttonColor="info"
                        fullWidth
                        type="submit"
                        // to={"/chatbot-template/add-template"}
                        // component={Link}
                      >
                        Add Templates
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </SuiDialog>
      </SuiBox>
      {customerInfo.length > 0 ? (
        <>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "500",
              backgroundColor: "#DEE7F7",
              padding: "10px 8px 10px 8px",
              width: "820px",
            }}
          >
            Please select the chat templates in the order you want them merged.
            If you deselect a template, the order will adjust automatically.
          </p>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TenantTemplateTable;
