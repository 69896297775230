export const TenantTableHeader = [
  
  {
    id: "1",
    align: "left",
    width: "10px",
    label: "",
  },
 
  {
    id: "3",
    align: "left",
    width: "150px",
    label: "Template Name",
  },
  {
    id: "5",
    align: "left",
    width: "450px",
    label: "Subscription Plan Name",
  },

  {
    id: "6",
    align: "left",
    width: "100px",
    label: "Action",
  }
];
export const TenantTemplateTableHeader = [
  {
    id: "0",
    align: "start",
    width: "10px",
    label: "",
  },
  
  {
    id: "1",
    align: "start",
    width: "50%",
    label: "Template Name",
  },
  {
    id: "2",
    align: "start",
    width: "50%",
    label: "Code",
  },
  {
    id: "3",
    align: "start",
    width: "50%",
    label: "Action",
  },
];
export const TenantChatBotTableHeader = [

  {
    id: "1",
    align: "left",
    width: "10px",
    label: "",
  },
  {
    id: "1",
    align: "left",
    width: "150px",
    label: "Template Name",
  },
  {
    id: "2",
    align: "left",
    width: "450px",
    label: "Code",
  },
  {
    id: "3",
    align: "left",
    width: "100px",
    label: "Action",
  }
];
export const TenantChatBotTableHeaderMearge = [

  {
    id: "0",
    align: "left",
    width: "10px",
    label: "",
  },
  {
    id: "1",
    align: "left",
    width: "150px",
    label: "Template Name",
  },
  {
    id: "2",
    align: "left",
    width: "450px",
    label: "Code",
  },
  {
    id: "3",
    align: "left",
    width: "100px",
    label: "Action",
  }
];