import { apiController } from "layouts/customer/CustomerTable";
import { baseAPIService } from ".";
const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    loginWithPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/login",
          method: "POST",
          body: data,
        };
      },
    }),
    tenantLoginWithPassword: build.mutation({
      query: (data) => {
        return {
          url: "tenant/login",
          method: "POST",
          body: data,
        };
      },
    }),
    downloadpaymentInvoiceCDRReport: build.mutation({
      query: (body) => {
        return {
          url: `admin/users/invoice/cdr/download/${body.id}/${body.No}`,
          method: "GET",
        };
      },
    }),
    paymentInvoiceReport: build.mutation({
      query: (values) => {
        return {
          url: `admin/users/invoice/${values.id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    downloadpaymentInvoicePDFReport: build.mutation({
      query: (body) => {
        return {
          url: `admin/users/invoice/download/${body.id}/${body.No}`,
          method: "GET",
        };
      },
    }),
    forgotPassword: build.mutation({
      query: (data) => {
        return {
          url: "tenant/forgotPassword",
          method: "POST",
          body: data,
        };
      },
    }),
    refreshTokens: build.mutation({
      query: (data) => {
        return {
          url: "admin/refreshtoken",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: ({ data, authToken }) => {
        return {
          url: "admin/verifyOtp",
          method: "POST",
          headers: {
            authtoken: authToken,
          },
          body: data,
        };
      },
    }),
    resetPassword: build.mutation({
      query: (data) => {
        return {
          url: "tenant/resetpassword",
          method: "POST",
          body: data,
        };
      },
    }),
    logout: build.mutation({
      query: (headers) => {
        return {
          url: "admin/logout",
          method: "POST",
          headers: headers,
          // body: null,
        };
      },
    }),
    getAdminInfo: build.mutation({
      query: () => {
        return {
          url: "admin/",
          method: "GET",
        };
      },
    }),
    getTokenInfo: build.mutation({
      query: () => {
        return {
          url: "/tenant/calendlyDetails",
          method: "GET",
        };
      },
    }),
    getCustomerInfo: build.mutation({
      query: (params) => {
        return {
          url: "admin/users",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAdminDashboardInfo: build.mutation({
      query: (params) => {
        return {
          url: "dashboard",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getTenantDashboardInfo: build.mutation({
      query: (params) => {
        return {
          url: "dashboard/tenant",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getTemplateInfo: build.mutation({
      query: (params) => {
        return {
          url: "template",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getTenantTemplateInfo: build.mutation({
      query: ({ id, params }) => {
        return {
          url: `template/tenantTemplate/${id}`,
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getDefaultTemplateInfo: build.mutation({
      query: (params) => {
        return {
          url: "template/default",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getReferenceParametersInfo: build.mutation({
      query: (params) => {
        return {
          url: "reference",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAllPermissionAdmin: build.mutation({
      query: (authToken) => {
        document.cookie = `Bearer ${authToken}`;
        return {
          url: "admin/getUserPermissions",
          method: "GET",
          /* headers: {
            // Authorization:  authToken,
            Authorization: `Bearer ${authToken}`,
          }, */
          // signal: apiController.signal,
        };
      },
    }),
    getRolesInfo: build.mutation({
      query: (params) => {
        return {
          url: "roles",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAllTimezone: build.mutation({
      query: () => {
        return {
          url: "country/getTimezone",
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    getStatesByCountry: build.mutation({
      query: (id) => {
        return {
          url: `country/getStates/${id}`,
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    getCityByState: build.mutation({
      query: (id) => {
        return {
          url: `/country/getCities/${id}`,
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAllCountry: build.mutation({
      query: () => {
        return {
          url: "country",
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAllRolesInfo: build.mutation({
      query: () => {
        return {
          url: "/roles/getAllRoles",
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    getTenantInfo: build.mutation({
      query: (params) => {
        return {
          url: "tenant/",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getReportInfo: build.mutation({
      query: (params) => {
        return {
          url: "report/",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getTenantReportInfo: build.mutation({
      query: (params) => {
        return {
          url: "report/tenantReport",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    downloadcsv: build.mutation({
      query: () => {
        return {
          url: "tenant/sampleCsv",
          method: "GET",
          responseType: "blob",
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    exportcsv: build.mutation({
      query: (params) => {
        return {
          url: "tenant/exportTenant",
          method: "GET",
          params: params,
          responseType: "blob",
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    exportcsvReport: build.mutation({
      query: (params) => {
        return {
          url: "report/exportAdminReport",
          method: "GET",
          params: params,
          responseType: "blob",
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    exportcsvTenantReport: build.mutation({
      query: (params) => {
        return {
          url: "report/exportTenantReport",
          method: "GET",
          params: params,
          responseType: "blob",
          responseHandler: (response) => response.blob(),
        };
      },
    }),
    getPlanInfo: build.mutation({
      query: (params) => {
        return {
          url: "subscription/",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getAllPlanInfo: build.mutation({
      query: (params) => {
        return {
          url: "subscription/getAllSubscription",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getModulesInfo: build.mutation({
      query: () => {
        return {
          url: "modules",
          method: "GET",
          // params: params,
          signal: apiController.signal,
        };
      },
    }),
    downloadData: build.mutation({
      query: (params) => {
        return {
          url: "admin/users/download",
          method: "GET",
          params: params,
        };
      },
    }),
    updateRole: build.mutation({
      query: ({ data, id }) => {
        return {
          url: `roles/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateTenant: build.mutation({
      query: ({ data, id }) => {
        return {
          url: `tenant/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateTemplate: build.mutation({
      query: ({ data, id }) => {
        return {
          url: `template/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updatePlan: build.mutation({
      query: ({ data, id }) => {
        return {
          url: `subscription/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateTenantStatus: build.mutation({
      query: ({ data, id }) => {
        console.log(data);
        return {
          url: `/tenant/tenantOnOff/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updatePlanStatus: build.mutation({
      query: ({ data, id }) => {
        console.log(data);
        return {
          url: `/subscription/subscriptionOnOff/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateCustomer: build.mutation({
      query: ({ data, id }) => {
        console.log(data, id);
        return {
          url: `admin/users/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),

    // INTEGRATION
    getIntegrationById: build.mutation({
      query: (values) => {
        return {
          url: `/integration`,
          method: "GET",
        };
      },
    }),
    updateIntegration: build.mutation({
      query: (data) => {
        return {
          url: `/integration`,
          method: "POST",
          body: data,
        };
      },
    }),

    portInNumbers: build.mutation({
      query: (values) => {
        return {
          url: `admin/users/number-port-in/${values.id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    pricing: build.mutation({
      query: () => {
        return {
          url: "admin/pricing",
          method: "GET",
        };
      },
    }),
    updatePricing: build.mutation({
      query: (data) => {
        return {
          url: "admin/pricing",
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteNumberPort: build.mutation({
      query: (id) => {
        return {
          url: `admin/users/number-port-in/${id}`,
          method: "DELETE",
        };
      },
    }),
    deleteUser: build.mutation({
      query: (id) => {
        return {
          url: `admin/users/${id}`,
          method: "DELETE",
        };
      },
    }),
    deleteTenant: build.mutation({
      query: (id) => {
        return {
          url: `tenant/${id}`,
          method: "DELETE",
        };
      },
    }),
    deleteTemplate: build.mutation({
      query: (id) => {
        return {
          url: `template/${id}`,
          method: "DELETE",
        };
      },
    }),
    deleteTemplateQuestion: build.mutation({
      query: (id) => {
        return {
          url: `template/question/${id}`,
          method: "DELETE",
        };
      },
    }),
    deletePlan: build.mutation({
      query: (id) => {
        return {
          url: `subscription/${id}`,
          method: "DELETE",
        };
      },
    }),
    customerGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `admin/users/${cus_id}`,
          method: "GET",
        };
      },
    }),
    roleGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `roles/${cus_id}`,
          method: "GET",
        };
      },
    }),
    tenantGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `tenant/${cus_id}`,
          method: "GET",
        };
      },
    }),
    templateGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `template/${cus_id}`,
          method: "GET",
        };
      },
    }),
    planGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `subscription/${cus_id}`,
          method: "GET",
        };
      },
    }),
    getAlldid: build.mutation({
      query: (params) => {
        return {
          url: "admin/dids",
          method: "GET",
          params: params,
        };
      },
    }),
    getDidCountry: build.mutation({
      query: () => {
        return {
          url: "admin/did/filter/countries",
          method: "GET",
        };
      },
    }),
    getCustomerAccountNo: build.mutation({
      query: () => {
        return {
          url: "admin/did/filter/customers",
          method: "GET",
        };
      },
    }),
    downloadDIDnumbers: build.mutation({
      query: (params) => {
        return {
          url: "admin/did/download",
          method: "GET",
          params: params,
        };
      },
    }),
    getAssignDID: build.mutation({
      query: (params) => {
        return {
          url: "admin/assign-did",
          method: "GET",
          params: params,
        };
      },
    }),
    getAssignSipTrunk: build.mutation({
      query: (id) => {
        return {
          url: `admin/assign-did/sip-trunk/${id}`,
          method: "GET",
        };
      },
    }),
    AssignDID: build.mutation({
      query: (data) => {
        return {
          url: `admin/assign-did`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteAssignDID: build.mutation({
      query: (id) => {
        return {
          url: `admin/assign-did/${id}`,
          method: "DELETE",
        };
      },
    }),
    assignDIDCSV: build.mutation({
      query: () => {
        return {
          url: "admin/assign-did/sample",
          method: "GET",
        };
      },
    }),
    importDIDCSV: build.mutation({
      query: (data) => {
        return {
          url: "admin/assign-did/import",
          method: "POST",
          body: data,
        };
      },
    }),
    statusUpdate: build.mutation({
      query: (data) => {
        return {
          url: `admin/assign-did/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getRequestApproval: build.mutation({
      query: (params) => {
        return {
          url: `admin/did-request-approval`,
          method: "GET",
          params: params,
        };
      },
    }),
    updateRequestApproval: build.mutation({
      query: (data, params) => {
        return {
          url: `admin/did-request-approval/${data.id}`,
          method: "PATCH",
          body: data,
          params: params,
        };
      },
    }),
    termsConditions: build.mutation({
      query: (params) => {
        return {
          url: `admin/pages`,
          method: "GET",
          params: params,
        };
      },
    }),
    postPages: build.mutation({
      query: (data) => {
        return {
          url: `admin/pages/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getPagesById: build.mutation({
      query: (id) => {
        return {
          url: `admin/pages/${id}`,
          method: "GET",
        };
      },
    }),
    deletePages: build.mutation({
      query: (id) => {
        return {
          url: `admin/pages/${id}`,
          method: "DELETE",
        };
      },
    }),
    changePassword: build.mutation({
      query: (data) => {
        return {
          url: `admin`,
          method: "PUT",
          body: data,
        };
      },
    }),
    resetPasswordSipTrunk: build.mutation({
      query: (data) => {
        return {
          url: `admin/users/sip-trunk/password`,
          method: "PUT",
          body: data,
        };
      },
    }),
    dayOfPlan: build.mutation({
      query: (params) => {
        return {
          url: `admin/time_of_day/category`,
          method: "GET",
          params: params,
        };
      },
    }),
    dayOfPlanCategory: build.mutation({
      query: (id) => {
        return {
          url: `admin/time_of_day/category/${id}`,
          method: "GET",
        };
      },
    }),
    callRecordReport: build.mutation({
      query: (params) => {
        return {
          url: `admin/users/cdrreport`,
          method: "GET",
          params: params,
        };
      },
    }),
    activeSubscription: build.mutation({
      query: (id) => {
        return {
          url: `admin/users/active_subscription/${id}`,
          method: "GET",
        };
      },
    }),
    createCustomer: build.mutation({
      query: (data) => {
        return {
          url: `admin/users`,
          method: "POST",
          body: data,
        };
      },
    }),
    testCalendlyToken: build.mutation({
      query: (data) => {
        return {
          url: `/tenant/calendlyValidate`,
          method: "POST",
          body: data,
        };
      },
    }),
    addCsv: build.mutation({
      query: (data) => {
        return {
          url: `tenant/importTenant`,
          method: "POST",
          body: data,
        };
      },
    }),
    createTenant: build.mutation({
      query: (data) => {
        return {
          url: `tenant/`,
          method: "POST",
          body: data,
        };
      },
    }),
    createTemplate: build.mutation({
      query: (data) => {
        return {
          url: `template`,
          method: "POST",
          body: data,
        };
      },
    }),
    createPlan: build.mutation({
      query: (data) => {
        return {
          url: `subscription/`,
          method: "POST",
          body: data,
        };
      },
    }),
    createRoles: build.mutation({
      query: (data) => {
        return {
          url: `roles/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSpendLimitData: build.mutation({
      query: (id) => {
        return {
          url: `admin/users/spend-limit/${id}`,
          method: "GET",
        };
      },
    }),
    SpendLimitData: build.mutation({
      query: (data) => {
        return {
          url: `admin/users/spend-limit/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    cronDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/report/cron`,
          method: "GET",
          params: params,
        };
      },
    }),
    cronExport: build.mutation({
      query: () => {
        return {
          url: `admin/report/cron/export`,
          method: "GET",
        };
      },
    }),
    getRolesDropdown: build.mutation({
      query: () => {
        return {
          url: `admin/roles/active`,
          method: "GET",
        };
      },
    }),
    createAdmins: build.mutation({
      query: (data) => {
        return {
          url: `admin`,
          method: "POST",
          body: data,
        };
      },
    }),
    GetAdminByID: build.mutation({
      query: (id) => {
        return {
          url: `admin/${id}`,
          method: "GET",
        };
      },
    }),
    UpdateAdmins: build.mutation({
      query: (data, params) => {
        return {
          url: `admin/${data.id}`,
          method: "PUT",
          body: data,
          params: params,
        };
      },
    }),
    GetAllAdmin: build.mutation({
      query: (params) => {
        return {
          url: `admin/all`,
          method: "GET",
          params: params,
        };
      },
    }),
    downloadAdminDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    deleteAdmin: build.mutation({
      query: (id) => {
        return {
          url: `admin/${id}`,
          method: "DELETE",
        };
      },
    }),
    deleteRole: build.mutation({
      query: (id) => {
        return {
          url: `roles/${id}`,
          method: "DELETE",
        };
      },
    }),
    importAdmin: build.mutation({
      query: () => {
        return {
          url: `admin/sample`,
          method: "GET",
        };
      },
    }),
    importFormAdmin: build.mutation({
      query: (data) => {
        return {
          url: `admin/import`,
          method: "POST",
          body: data,
        };
      },
    }),

    mergeTemplates: build.mutation({
      query: (body) => {
        console.log("1234567", body);
        return {
          url: `chat/merge/${body?.id}?search=${body?.searchName}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${body?.token}`,
          },
        };
      },
    }),
    createMergeTemplate: build.mutation({
      query: (body) => {
        console.log("bdyddd", body);

        return {
          url: `chat/${body?.id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${body?.token}`,
          },
          body: { templateId: body?.templateId },
        };
      },
    }),

    AddTemplate: build.mutation({
      query: (body) => {
        return {
          url: `chat/add/${body?.id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${body?.token}`,
          },
          body: { templateId: body?.templateId },
        };
      },
    }),
  }),

  overrideExisting: true,
});
export const {
  useImportAdminMutation,
  useImportFormAdminMutation,
  useDeleteAdminMutation,
  useDownloadAdminDetailsMutation,
  useGetAdminByIDMutation,
  useGetAllAdminMutation,
  useCreateAdminsMutation,
  useUpdateAdminsMutation,
  useGetRolesDropdownMutation,
  useGetSpendLimitDataMutation,
  useCronExportMutation,
  useSpendLimitDataMutation,
  useLoginWithPasswordMutation,
  useCallRecordReportMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useCreateCustomerMutation,
  useLogoutMutation,
  useDayOfPlanCategoryMutation,
  useGetAdminInfoMutation,
  useRefreshTokensMutation,
  useGetCustomerInfoMutation,
  useCronDetailsMutation,
  useDownloadDataMutation,
  useCustomerGetByIdMutation,
  useUpdateCustomerMutation,
  useGetIntegrationByIdMutation,
  useUpdateIntegrationMutation,
  usePortInNumbersMutation,
  usePricingMutation,
  useUpdatePricingMutation,
  useDeleteNumberPortMutation,
  useGetAlldidMutation,
  useGetDidCountryMutation,
  useGetCustomerAccountNoMutation,
  useDownloadDIDnumbersMutation,
  useGetAssignDIDMutation,
  useGetAssignSipTrunkMutation,
  useResetPasswordSipTrunkMutation,
  useAssignDIDMutation,
  useDeleteAssignDIDMutation,
  useAssignDIDCSVMutation,
  useImportDIDCSVMutation,
  useStatusUpdateMutation,
  useGetRequestApprovalMutation,
  useUpdateRequestApprovalMutation,
  useTermsConditionsMutation,
  usePostPagesMutation,
  useGetPagesByIdMutation,
  useDeletePagesMutation,
  useChangePasswordMutation,
  useDayOfPlanMutation,
  useActiveSubscriptionMutation,
  useDownloadpaymentInvoicePDFReportMutation,
  useDownloadpaymentInvoiceCDRReportMutation,
  usePaymentInvoiceReportMutation,
  useCreateRolesMutation,
  useGetRolesInfoMutation,
  useGetModulesInfoMutation,
  useRoleGetByIdMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useTenantLoginWithPasswordMutation,
  useGetAllPermissionAdminMutation,
  useGetTenantInfoMutation,
  useDeleteUserMutation,
  useGetAllRolesInfoMutation,
  useGetAllTimezoneMutation,
  useGetAllCountryMutation,
  useGetCityByStateMutation,
  useGetStatesByCountryMutation,
  useUpdateTenantStatusMutation,
  useCreateTenantMutation,
  useTenantGetByIdMutation,
  useUpdateTenantMutation,
  useDeleteTenantMutation,
  useGetPlanInfoMutation,
  useUpdatePlanStatusMutation,
  useCreatePlanMutation,
  usePlanGetByIdMutation,
  useUpdatePlanMutation,
  useDeletePlanMutation,
  useDownloadcsvMutation,
  useExportcsvMutation,
  useAddCsvMutation,
  useGetTemplateInfoMutation,
  useGetTenantTemplateInfoMutation,
  useGetReferenceParametersInfoMutation,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useTemplateGetByIdMutation,
  useUpdateTemplateMutation,
  useGetDefaultTemplateInfoMutation,
  useGetAllPlanInfoMutation,
  useDeleteTemplateQuestionMutation,
  useTestCalendlyTokenMutation,
  useGetTokenInfoMutation,
  useGetReportInfoMutation,
  useExportcsvReportMutation,
  useGetAdminDashboardInfoMutation,
  useGetTenantDashboardInfoMutation,
  useGetTenantReportInfoMutation,
  useExportcsvTenantReportMutation,
  useMergeTemplatesMutation,
  useCreateMergeTemplateMutation,
  useAddTemplateMutation
} = AuthApi;
